import { EagleApiInterface, EagleModuleApiInterface } from "kernel/api/api";
import qs from "qs";
export default (api: EagleApiInterface) => {
  api.collection.gaReportApi = <EagleModuleApiInterface>{
    pageview: (params: AnyObject) =>
      api.request({
        url: `/api/ga-report/pageview`,
        type: "get",
        params,
      }),
    users: (params: AnyObject) =>
      api.request({
        url: `/api/ga-report/users`,
        type: "get",
        params,
      }),
  };

  api.collection.baseApi = <EagleModuleApiInterface>{
    lineOauthLogin: (params: AnyObject) =>
      api.request({
        url: `/api/member/oauth-login/line`,
        type: "post",
        params,
      }),
    storeLineOauthLogin: (providerId: string, params: AnyObject) =>
      api.request({
        url: `/api/member/provider-oauth-login/${providerId}`,
        type: "post",
        params,
      }),
    renewStoreToken: (
      provider_id: string,
      store_id: string,
      params: AnyObject
    ) =>
      api.request({
        url: `/api/member/renew-token/${provider_id}/store/${store_id}`,
        type: "put",
        params,
      }),
    application: (params = null) =>
      api.request({
        url: `/api/application`,
        type: "get",
        params,
      }),
    storageInfo: () =>
      api.request({
        url: `/api/storage`,
        type: "get",
      }),
    mailTemplates: () =>
      api.request({
        url: `/api/mail/templates`,
        type: "get",
      }),
    fcmPush: (params: AnyObject) =>
      api.request({
        url: `/api/fcm`,
        type: "post",
        params,
      }),
    sitemap: () =>
      api.request({
        url: `/api/sitemap`,
        type: "get",
      }),
    appConfigRead: (id: string) =>
      api.request({
        url: `/api/app-config/${id}`,
        type: "get",
      }),
    appConfigUpdate: (id: string, params: AnyObject) =>
      api.request({
        url: `/api/app-config/${id}/update`,
        type: "put",
        params,
      }),
  };

  api.collection.photoApi = <EagleModuleApiInterface>{
    upload: (
      formData: FormData,
      onProgress = null,
      params: { [key: string]: any }
    ) => {
      const uploadParams = {
        ...params,
      };
      const query = window.eagleLodash.isEmpty(uploadParams)
        ? ""
        : `?${qs.stringify(uploadParams, { arrayFormat: "indices" })}`;

      return api.request({
        url: `/api/member/photo/upload${query}`,
        type: "post",
        data: formData,
        martipart: true,
        onUploadProgress: onProgress,
      });
    },
  };

  api.collection.fileApi = <EagleModuleApiInterface>{
    download: (params: AnyObject) =>
      api.request({
        url: `/api/file/download`,
        type: "post",
        responseType: "blob",
        params,
      }),
    upload: (type: string, formData: FormData, onProgress = null) => {
      const params = {
        type,
      };
      const query = window.eagleLodash.isEmpty(params)
        ? ""
        : `?${qs.stringify(params, { arrayFormat: "indices" })}`;

      return api.request({
        url: `/api/file/upload${query}`,
        type: "post",
        data: formData,
        martipart: true,
        onUploadProgress: onProgress,
      });
    },
    read: (id: string) =>
      api.request({
        url: `/api/file/${id}`,
        type: "get",
      }),
    index: (params = {}) =>
      api.request({
        url: `/api/file/index`,
        type: "get",
        params,
      }),
    delete: (id: string) =>
      api.request({
        url: `/api/file/${id}`,
        type: "delete",
      }),
    batchDelete: (targets: string[]) =>
      api.request({
        url: `/api/file/batch`,
        type: "delete",
        params: { ids: targets },
      }),
  };

  api.collection.otpApi = <EagleModuleApiInterface>{
    index: (params = {}) =>
      api.request({
        url: `/api/otp/index`,
        type: "get",
        params,
      }),
    delete: (id: string) =>
      api.request({
        url: `/api/otp/${id}`,
        type: "delete",
      }),
    batchDelete: (targets: string[]) =>
      api.request({
        url: `/api/otp/batch`,
        type: "delete",
        params: { ids: targets },
      }),
  };

  api.collection.applyPayApi = <EagleModuleApiInterface>{
    overview: ({ provider, params }) =>
      api.request({
        url: `/api/provider/${provider}/apply-pay/index`,
        type: "get",
        params,
      }),
    getCanApplyStore: ({ provider, params }) =>
      api.request({
        url: `/api/provider/${provider}/store/getCanApply`,
        type: "get",
        params,
      }),
    create: ({ provider, store, params }) =>
      api.request({
        url: `/api/provider/${provider}/apply-pay/${store}/create`,
        type: "post",
        params,
      }),
    getStoreApplyPay: ({ provider, store, params }) =>
      api.request({
        url: `/api/provider/${provider}/apply-pay/${store}/orders/index`,
        type: "get",
        params,
      }),
    read: ({ provider, applyPayId, params }) =>
      api.request({
        url: `/api/provider/${provider}/apply-pay/${applyPayId}/read`,
        type: "get",
        params,
      }),
    update: ({ provider, applyPayId, params }) =>
      api.request({
        url: `/api/provider/${provider}/apply-pay/${applyPayId}/update`,
        type: "put",
        params,
      }),
    // 客戶視角的請款列表
    storeApplyPayIndex: ({ store, params }) =>
      api.request({
        url: `/api/store/${store}/applyPay/index`,
        type: "get",
        params,
      }),
    storeApplyPayUpdate: ({ store_id, applyPay_id, account_no }) =>
      api.request({
        url: `/api/store/${store_id}/applyPay/${applyPay_id}/update?account_no=${account_no}`,
        type: "put",
      }),
  };

  // 報表
  api.collection.reportApi = <EagleModuleApiInterface>{
    // 共用
    reportByDate: ({ tokenRole, tokenRoleId, key, type, params }) =>
      api.request({
        /*
          url: '/api/store/{tokenRoleId}/report/{key}/{type}'

          tokenRole: 'store'/'provider'
          tokenRoleId: tokenRoleId(provider_id/store_id) 

          ** 此 function 支援取 供應商/客戶/商品的 daily/monthly 數據
          key: 'product'/'provider'/'store'
          type: 'day'/'month'

          params: { start_date: 2024-01-01, end_date: 2024-02-29, product_id/provider_id/store_id: id,id,id,... }
        */
        url: `/api/${tokenRole}/${tokenRoleId}/report/${key}/${type}`,
        type: "get",
        params,
      }),

    // 供應商端
    providerGetMainData: ({ provider, params, mainDataApiClass }) =>
      api.request({
        url: `/api/provider/${provider}/report/${mainDataApiClass}`,
        type: "get",
        params,
      }),
    // 下方 2 支api合併至上方 providerGetMainData
    // providerGetStore: ({ provider, params }) =>
    //   api.request({
    //     url: `/api/provider/${provider}/report/stores`,
    //     type: "get",
    //     params,
    //   }),
    // providerGetProduct: ({ provider, params }) =>
    //   api.request({
    //     url: `/api/provider/${provider}/report/products`,
    //     type: "get",
    //     params,
    //   }),

    // store 商店端
    storeReportGetProduct: ({ store, params }) =>
      api.request({
        url: `/api/store/${store}/report/products`,
        type: "get",
        params,
      }),
    storeReportGetProvider: ({ store, params }) =>
      api.request({
        url: `/api/store/${store}/report/providers`,
        type: "get",
        params,
      }),
  };
};
